/* You can add global styles to this file, and also import other style files */

p, h1, h2, h3, button {
    font-family: 'Roboto Slab', sans-serif;
  }
.main-container {
  font-family: 'Roboto Slab', sans-serif;
  max-height: 100%;
}
.headline {
  background-color: #789d4a;
  padding: 40px;
  font-size: 6vw;
  text-align: center;
  color: #fff;
  font-weight: 800;
}

.end_arrangement {
  background-color: #789d4a;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  width: 350px;
  height: 125px;
  text-align: center;
  padding-top: 40px;
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.end_arrangement.disabled {
  background-color: rgba(120, 157, 74, 0.44);
  color: #8a8a8a;
}

.start_arrangement {
  background-color: #789d4a;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  width: 350px;
  height: 125px;
  text-align: center;
  padding-top: 40px;
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.start_arrangement.disabled {
  background-color: rgba(120, 157, 74, 0.44);
  color: #8a8a8a;
}

.confirm_background {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.9;
}
.confirm_container {
  position: fixed;
  width: 70vw;
  top: 30vh;
  left: 15vw;
  background: #fff;
  z-index: 1000;
  border-radius: 5px;
  overflow: hidden;
}
.confirm_container.qr {
  top: 16vh;
}

.confirm_headline {
  background-color: #18332f;
  color: #fff;
  width: 100%;
  height: 90px;
  text-align: center;
  line-height: 90px;
  font-size: 45px;
}

.confirm_headline.big {
  height: 150px;
  font-size: 60px;
  padding-top: 25px;
}

.confirm_headline.error {
  background-color: #ff0000;
}

.confirm_text {
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 25px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.confirm_text.big {
  font-size: 50px;
}

.left {
  width: 50%;
  height: 15vh;
  line-height: 15vh;
  text-align: center;
  font-size: 35px;
  background-color: #cf4520;
  color: #fff;
  float: left;
}
.right {
  width: 50%;
  height: 15vh;
  line-height: 15vh;
  text-align: center;
  font-size: 35px;
  background-color: #789d4a;
  color: #fff;
  float: right;
}

.middle {
  width: 50%;
  height: 15vh;
  line-height: 15vh;
  text-align: center;
  font-size: 35px;
  background-color: #789d4a;
  color: #fff;
  margin-left: 25%;
}

.middle.full {
  width: 100%;
  margin-left: 0;
  font-size: 55px;
}

.version {
  background-color: #1c1f2a;
  color: grey;
  width: 200px;
  height: 70px;
  border-radius: 20px;
  padding: 10px 5px 5px;
}
